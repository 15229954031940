import React, { useEffect, useRef, useState } from "react";
import { Button, Flex, Drawer } from 'antd';
import { CameraFilled, OpenAIOutlined } from '@ant-design/icons';
import * as THREE from "three";
import ImageUploader from "./ImageUploader";


const Video360 = () => {
        const videoRef = useRef(null);
        const canvasRef = useRef(null);
        const drawingCanvasRef = useRef(null);
        const [isDrawingMode, setIsDrawingMode] = useState(false);
        const containerRef = useRef(null);

        const [open, setOpen] = useState(false);
        const showDrawer = () => {
                setOpen(true);
        };
        const onClose = () => {
                setOpen(false);
        };

        // 添加绘图功能
        const addDrawingControls = () => {
                let isDrawing = false;
                let lastX = 0;
                let lastY = 0;
                const drawingCanvas = drawingCanvasRef.current;
                const drawingContext = drawingCanvas.getContext("2d");

                const getPosition = (e) => {
                        let rect = drawingCanvas.getBoundingClientRect();
                        return { x: e.clientX - rect.left, y: e.clientY - rect.top };
                };

                const onDrawStart = (e) => {
                        isDrawing = true;
                        const pos = getPosition(e);
                        lastX = pos.x;
                        lastY = pos.y;
                };

                const onDrawMove = (e) => {
                        if (!isDrawing) return;
                        const pos = getPosition(e);
                        drawingContext.beginPath();
                        drawingContext.moveTo(lastX, lastY);
                        drawingContext.lineTo(pos.x, pos.y);
                        drawingContext.strokeStyle = "red";
                        drawingContext.lineWidth = 2;
                        drawingContext.stroke();
                        drawingContext.closePath();
                        lastX = pos.x;
                        lastY = pos.y;
                };

                const onDrawEnd = () => {
                        isDrawing = false;
                };

                drawingCanvas.addEventListener("mousedown", onDrawStart);
                drawingCanvas.addEventListener("mousemove", onDrawMove);
                drawingCanvas.addEventListener("mouseup", onDrawEnd);
                drawingCanvas.addEventListener("mouseout", onDrawEnd);

                drawingCanvas.addEventListener("touchstart", (e) => {
                        if (e.touches.length === 1) {
                                e.preventDefault();
                                const touch = e.touches[0];
                                const pos = getPosition(touch);
                                lastX = pos.x;
                                lastY = pos.y;
                                isDrawing = true;
                        }
                });

                drawingCanvas.addEventListener("touchmove", (e) => {
                        if (e.touches.length === 1 && isDrawing) {
                                e.preventDefault();
                                const touch = e.touches[0];
                                const pos = getPosition(touch);
                                drawingContext.beginPath();
                                drawingContext.moveTo(lastX, lastY);
                                drawingContext.lineTo(pos.x, pos.y);
                                drawingContext.strokeStyle = "red";
                                drawingContext.lineWidth = 2;
                                drawingContext.stroke();
                                drawingContext.closePath();
                                lastX = pos.x;
                                lastY = pos.y;
                        }
                });

                drawingCanvas.addEventListener("touchend", onDrawEnd);

                // 在 cleanup 中清除事件监听器
                return () => {
                        drawingCanvas.removeEventListener("mousedown", onDrawStart);
                        drawingCanvas.removeEventListener("mousemove", onDrawMove);
                        drawingCanvas.removeEventListener("mouseup", onDrawEnd);
                        drawingCanvas.removeEventListener("mouseout", onDrawEnd);
                        drawingCanvas.removeEventListener("touchstart", onDrawStart);
                        drawingCanvas.removeEventListener("touchmove", onDrawMove);
                        drawingCanvas.removeEventListener("touchend", onDrawEnd);
                };
        };

        // 清除绘图画布
        const clearDrawingCanvas = () => {
                const drawingCanvas = drawingCanvasRef.current;
                const drawingContext = drawingCanvas.getContext("2d");
                drawingContext.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);
        };

        const takeScreenshot = () => {
                const fullCanvas = document.createElement("canvas");
                fullCanvas.width = window.innerWidth;
                fullCanvas.height = window.innerHeight;
                const fullContext = fullCanvas.getContext("2d");

                const webglCanvas = canvasRef.current;
                const drawingCanvas = drawingCanvasRef.current;

                if (!webglCanvas || !drawingCanvas) {
                        console.error("Canvas elements not available for screenshot.");
                        return;
                }

                // 获取 WebGL Canvas 的数据 URL
                const webglDataURL = webglCanvas.toDataURL("image/png");

                const webglImage = new Image();
                webglImage.onload = () => {
                        // 将 WebGL Canvas 图像绘制到截图 Canvas 上
                        fullContext.drawImage(webglImage, 0, 0, fullCanvas.width, fullCanvas.height);

                        // 在其上绘制绘图 Canvas
                        fullContext.drawImage(drawingCanvas, 0, 0, fullCanvas.width, fullCanvas.height);

                        // 创建下载链接
                        const link = document.createElement("a");
                        link.href = fullCanvas.toDataURL("image/png");
                        link.download = "screenshot.png";
                        link.click();
                };

                webglImage.src = webglDataURL;
        };

        useEffect(() => {
                let cleanupDrawingControls;
                if (isDrawingMode) {
                        cleanupDrawingControls = addDrawingControls();
                }
                return () => {
                        if (cleanupDrawingControls) {
                                cleanupDrawingControls();
                        }
                };
        }, [isDrawingMode]);

        useEffect(() => {
                const container = containerRef.current;
                let video = videoRef.current;
                let canvas = canvasRef.current;
                let drawingCanvas = drawingCanvasRef.current;
                let renderer, scene, camera, texture, sphere;
                let textureCanvas, textureContext;
                let animateId;

                // Three.js WebGL 初始化
                // renderer = new THREE.WebGLRenderer({ canvas });

                renderer = new THREE.WebGLRenderer({ canvas, preserveDrawingBuffer: true });
                renderer.setPixelRatio(window.devicePixelRatio);
                renderer.setSize(container.clientWidth, container.clientHeight);

                // 场景和相机
                scene = new THREE.Scene();
                camera = new THREE.PerspectiveCamera(
                    75,
                    container.clientWidth / container.clientHeight,
                    1,
                    1100
                );

                // 屏幕调整
                const handleResize = () => {
                        renderer.setSize(container.clientWidth, container.clientHeight);
                        camera.aspect = container.clientWidth / container.clientHeight;
                        camera.updateProjectionMatrix();

                        drawingCanvas.width = container.clientWidth;
                        drawingCanvas.height = container.clientHeight;
                };
                window.addEventListener("resize", handleResize, false);

                let stream;
                let isUserInteracting = false,
                    lon = 0,
                    lat = 0,
                    phi = 0,
                    theta = 0,
                    onPointerDownPointerX = 0,
                    onPointerDownPointerY = 0,
                    onPointerDownLon = 0,
                    onPointerDownLat = 0;

                // 获取媒体流
                navigator.mediaDevices
                    .getUserMedia({
                            video: { width: { ideal: 1920 }, height: { ideal: 960 } },
                            audio: false,
                    })
                    .then((mediaStream) => {
                            stream = mediaStream;
                            video.srcObject = stream;
                            video.onloadedmetadata = () => {
                                    video
                                        .play()
                                        .then(() => {
                                                console.log("Video is playing");
                                                // 初始化纹理画布
                                                textureCanvas = document.createElement("canvas");
                                                textureCanvas.width = video.videoWidth;
                                                textureCanvas.height = video.videoHeight;
                                                textureContext = textureCanvas.getContext("2d");

                                                // 初始化绘图画布
                                                drawingCanvas.width = container.clientWidth;
                                                drawingCanvas.height = container.clientHeight;

                                                // 创建纹理
                                                texture = new THREE.CanvasTexture(textureCanvas);
                                                texture.minFilter = THREE.LinearFilter;
                                                texture.magFilter = THREE.LinearFilter;
                                                texture.format = THREE.RGBAFormat;

                                                // 创建球体
                                                const geometry = new THREE.SphereGeometry(500, 80, 60);
                                                geometry.scale(-1, 1, 1);
                                                const material = new THREE.MeshBasicMaterial({ map: texture });
                                                sphere = new THREE.Mesh(geometry, material);
                                                scene.add(sphere);

                                                // 添加控制
                                                addControls();
                                                // 开始动画
                                                animate();
                                        })
                                        .catch((err) => {
                                                console.error("Error playing video:", err);
                                        });
                            };
                    })
                    .catch((err) => {
                            console.error("获取媒体流失败: ", err);
                    });

                // 添加控制
                const addControls = () => {
                        // 光标事件
                        canvas.addEventListener("mousedown", onDocumentMouseDown, false);
                        canvas.addEventListener("mousemove", onDocumentMouseMove, false);
                        canvas.addEventListener("mouseup", onDocumentMouseUp, false);
                        canvas.addEventListener("wheel", onDocumentMouseWheel, false);
                        canvas.addEventListener("touchstart", onDocumentTouchStart, false);
                        canvas.addEventListener("touchmove", onDocumentTouchMove, false);
                        canvas.addEventListener("touchend", onDocumentTouchEnd, false);
                };

                const onDocumentMouseDown = (event) => {
                        isUserInteracting = true;
                        onPointerDownPointerX = event.clientX;
                        onPointerDownPointerY = event.clientY;
                        onPointerDownLon = lon;
                        onPointerDownLat = lat;
                };

                const onDocumentMouseMove = (event) => {
                        if (isUserInteracting) {
                                const clientX = event.clientX;
                                const clientY = event.clientY;
                                lon = (onPointerDownPointerX - clientX) * 0.1 + onPointerDownLon;
                                lat = (clientY - onPointerDownPointerY) * 0.1 + onPointerDownLat;
                        }
                };

                const onDocumentMouseUp = () => {
                        isUserInteracting = false;
                };

                const onDocumentMouseWheel = (event) => {
                        const fov = camera.fov + event.deltaY * 0.05;
                        camera.fov = THREE.MathUtils.clamp(fov, 10, 75);
                        camera.updateProjectionMatrix();
                };

                // 触摸事件处理函数
                const onDocumentTouchStart = (event) => {
                        if (event.touches.length === 1) {
                                event.preventDefault();
                                isUserInteracting = true;
                                onPointerDownPointerX = event.touches[0].clientX;
                                onPointerDownPointerY = event.touches[0].clientY;
                                onPointerDownLon = lon;
                                onPointerDownLat = lat;
                        }
                };

                const onDocumentTouchMove = (event) => {
                        if (isUserInteracting && event.touches.length === 1) {
                                event.preventDefault();
                                const clientX = event.touches[0].clientX;
                                const clientY = event.touches[0].clientY;
                                lon = (onPointerDownPointerX - clientX) * 0.1 + onPointerDownLon;
                                lat = (clientY - onPointerDownPointerY) * 0.1 + onPointerDownLat;
                        }
                };

                const onDocumentTouchEnd = () => {
                        isUserInteracting = false;
                };

                const animate = () => {
                        animateId = requestAnimationFrame(animate);
                        update();
                };

                const update = () => {
                        if (video.readyState >= video.HAVE_CURRENT_DATA) {
                                textureContext.drawImage(
                                    video,
                                    0,
                                    0,
                                    textureCanvas.width,
                                    textureCanvas.height
                                );
                                texture.needsUpdate = true;
                        }

                        lat = Math.max(-85, Math.min(85, lat));
                        phi = THREE.MathUtils.degToRad(90 - lat);
                        theta = THREE.MathUtils.degToRad(lon);

                        camera.target = new THREE.Vector3(
                            500 * Math.sin(phi) * Math.cos(theta),
                            500 * Math.cos(phi),
                            500 * Math.sin(phi) * Math.sin(theta)
                        );
                        camera.lookAt(camera.target);

                        renderer.render(scene, camera);
                };

                // 清理
                return () => {
                        console.log("Component unmounted");
                        window.removeEventListener("resize", handleResize);
                        cancelAnimationFrame(animateId);

                        canvas.removeEventListener("mousedown", onDocumentMouseDown);
                        canvas.removeEventListener("mousemove", onDocumentMouseMove);
                        canvas.removeEventListener("mouseup", onDocumentMouseUp);
                        canvas.removeEventListener("wheel", onDocumentMouseWheel);
                        canvas.removeEventListener("touchstart", onDocumentTouchStart);
                        canvas.removeEventListener("touchmove", onDocumentTouchMove);
                        canvas.removeEventListener("touchend", onDocumentTouchEnd);

                        if (stream) {
                                stream.getTracks().forEach((track) => track.stop());
                        }

                        if (renderer) {
                                renderer.dispose();
                        }
                        if (texture) {
                                texture.dispose();
                        }
                        if (sphere) {
                                sphere.geometry.dispose();
                                sphere.material.dispose();
                                scene.remove(sphere);
                        }
                };
        }, []);

        return (
            <div
                ref={containerRef}
                style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                }}
            >
                    <div
                        style={{
                                position: "absolute",
                                zIndex: 2,
                                display: "flex",
                                gap: "10px",
                        }}
                    >
                            <Flex gap="small" wrap>
                                    <Button type="primary" danger ghost onClick={() => setIsDrawingMode(!isDrawingMode)}>
                                            {isDrawingMode ? "閉じる" : "手書き"}
                                    </Button>
                                    {isDrawingMode && (
                                        <Button type="primary" danger ghost onClick={clearDrawingCanvas}>
                                                クリア
                                        </Button>
                                    )}
                                    <Button type="primary" icon={<CameraFilled />} onClick={takeScreenshot}>
                                            キャプチャ
                                    </Button>
                                    <Button type="primary" icon={<OpenAIOutlined />} onClick={showDrawer}>
                                            AI解析
                                    </Button>
                                    <Drawer title="AI解析" placement="bottom"　key="bottom" onClose={onClose} open={open}>
                                        <ImageUploader />
                                    </Drawer>
                            </Flex>
                    </div>
                    <video ref={videoRef} style={{ display: "none" }} />
                    <canvas
                        ref={canvasRef}
                        style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                        }}
                    />
                    <canvas
                        ref={drawingCanvasRef}
                        style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 1,
                                pointerEvents: isDrawingMode ? "auto" : "none",
                        }}
                    />
            </div>
        );
};

export default Video360;
