import React from 'react';
import './App.css';
import Video360 from "./components/Video360";

function App() {
    return (
        <div className="App">
            <Video360 />
        </div>
    );
}

export default App;
