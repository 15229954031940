import React from 'react';
import { Button, ConfigProvider, Flex } from 'antd';

function Teamspost({ resultData,resultUrl }) {
    const handleClick = async () => {
        const url = 'https://lhccai.webhook.office.com/webhookb2/ff193661-abb7-48d3-a231-02f14aaada5c@444e185b-723c-4e7b-8160-5ff2c2ae1bc6/IncomingWebhook/7e3d3a24c9d6450aaaa05869dd3a43bf/a560f8dc-ecd2-44f8-a110-f5c67f3c1339/V2GhqDZuyaOEktPgO3bTUzhwqCE6CXW5qOfcdAX73Hfh81';
        console.log(resultData);
        const data = {
            "type": "message",
            "attachments": [
                {
                    "contentType": "application/vnd.microsoft.card.adaptive",
                    "contentUrl": null,
                    "content": {
                        "type": "AdaptiveCard",
                        "version": "1.4",
                        "body": [
                            {
                                "type": "TextBlock",
                                "text": "解析結果: "
                            },
                            {
                                "type": "Image",
                                "url": resultUrl
                            },
                            {
                                "type": "TextBlock",
                                "wrap": true,
                                "spacing": "Padding",
                                "text":  resultData
                            }
                        ]
                    },
                    "name": null,
                    "thumbnailUrl": null
                }
            ]
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'no-cors',
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log('Response:', responseData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <Button color="default" variant="solid" onClick={handleClick}>Teamsチャンネルに送信</Button>
        </div>
    );
}

export default Teamspost;
